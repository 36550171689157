<template>
  <div class="manage-project-mortgage">
    <div class="container fluid">
      <div class="row justify-content-between mx-2 my-4">
        <h2 class="mb-1">Manage Bank Loans</h2>
        <mortgage-method-dropdown
          :bankLoanRoute="getBankLoanRoute()"
          :bankLoanHistoryRoute="getBankLoanHistoryRoute()"
          :otherRoute="getBankLoanOtherRoute()"
        ></mortgage-method-dropdown>
      </div>

      <h4 class="p-2">Leads</h4>
      <lead-table
        :detailRouteName="getDetailRouteName()"
        :getBankLoans="API.getBankLoans"
      ></lead-table>
    </div>
  </div>
</template>

<script>
import LeadTable from "@/components/GlobalComponents/Shared/Banker/LeadTable";
import { projectMortgage as mortgageAPI } from "@/api";
import projectMortgageMixin from "@/mixins/project/projectMortgageMixin";

export default {
  components: {
    LeadTable,
    MortgageMethodDropdown: () =>
      import(
        "@/components/GlobalComponents/Shared/ProjectSales/MortgageMethodDropdown"
      )
  },
  mixins: [projectMortgageMixin],
  props: {
    isAssigned: {
      type: Boolean,
      default: false
    }
  },
  data: function () {
    return {
      mortgageAPI,
      API: {}
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.initAPI();
  },
  methods: {
    initAPI() {
      this.API = mortgageAPI.getProjectMortgageAPI(this.isAssigned);
    },
    getDetailRouteName() {
      return this.isAssigned
        ? "ManageAssignedProjectMortgageBankLoansDetail"
        : "ManageProjectMortgageBankLoansDetail";
    }
  }
};
</script>

<style lang="scss">
.manage-project-mortgage {
}
</style>
