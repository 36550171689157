<template>
  <div class="bankloan-lead-table">
    <!-- ============================= Filter ============================ -->
    <filter-panel
      v-model="isFilterActive"
      @clear="clearFilter"
      @filter="filterData"
    >
      <div class="row p-2">
        <fd-input
          v-model="filter['appUser:name[partial]']"
          class="col-12 sm-col-6 md-col-4 px-1 mb-2"
          label="Buyer Name"
          type="text"
          @keyup.enter.native="filterData"
        >
        </fd-input>
      </div>
    </filter-panel>
    <!-- ========================= Table Listing ========================= -->
    <div class="card">
      <vue-good-table
        mode="remote"
        @on-page-change="onPageChange"
        @on-per-page-change="onPerPageChange"
        :columns="bankLoanTableColumns"
        :rows="bankLoanTableData"
        :totalRows="bankLoanTablePagination.total"
        :pagination-options="{
          enabled: true,
          mode: 'pages',
          perPage: 30,
          perPageDropdown: perPageOptions,
          dropdownAllowAll: false
        }"
        @on-row-click="toLeadDetail"
      >
        <div slot="emptystate">
          <no-data :message="`There is no available  lead`"></no-data>
        </div>
        <div slot="table-actions" class="p-1">
          <!-- <button
              :class="{ active: isFilterActive }"
              class="btn toggle"
              @click="filterToggle"
            >
              <i class="fas fa-filter"></i>
            </button> -->
          <fd-select
            v-model="status"
            label="Status"
            :options="availableStatus"
            :optionLabel="(item) => item.name"
            :optionValue="(item) => item.value"
            :showEmptyChoice="false"
            @change="getAllBankLoans"
          ></fd-select>
        </div>
        <template slot="table-row" slot-scope="props">
          <!-- Table Buttons -->
          <span v-if="props.column.field == 'actions'" @click.stop> </span>
          <span v-else-if="props.column.field == 'status'" @click.stop>
            <lead-status
              :status="props.row.status"
              statusClass="w-100"
            ></lead-status>
          </span>
          <!-- ======================== AGENT INFO ======================= -->
          <span
            v-else-if="
              props.column.field == 'assignedAgent' &&
              !_.isEmpty(props.row.projectPurchase.agent)
            "
            @click.stop
          >
            <p>{{ props.row.projectPurchase.agent.name }}</p>
            <span class="d-flex align-items-center">
              <i class="fas fa-phone mr-1"></i
              >{{ $getUserPhoneNumber(props.row.projectPurchase.agent.user) }}
            </span>
            <span class="d-flex align-items-center">
              <i class="fas fa-envelope mr-1"></i
              >{{ props.row.projectPurchase.agent.user.email }}
            </span>
          </span>
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
      </vue-good-table>
    </div>
  </div>
</template>

<script>
import vueGoodTable from "@/mixins/vue-good-table/mixin";
import { activeStatus, getLabel } from "@/enums/project/projectLeadStatus";

const availableStatus = [
  { name: "All", value: activeStatus.join("|") },
  ...activeStatus.map((status) => ({ name: getLabel(status), value: status }))
];

export default {
  components: {
    LeadStatus: () =>
      import("@/components/GlobalComponents/Shared/Banker/LeadStatus")
  },
  mixins: [vueGoodTable],
  props: {
    detailRouteName: {
      type: String,
      default: "ManageBankLoansDetails"
    },
    getBankLoans: {
      type: Function,
      required: true
    }
  },
  data: function () {
    return {
      status: availableStatus[0].value,
      availableStatus,
      bankLoanTableData: [],
      bankLoanTablePagination: {},
      bankLoanTableColumns: [
        {
          label: "Status",
          field: "status"
        },
        {
          label: "Buyer Name",
          field: "projectPurchaseReservation.purchaserName1"
        },
        {
          label: "Buyer Contact No.",
          field: "projectPurchaseReservation.phoneContact"
        },
        {
          label: "Buyer Email",
          field: "projectPurchaseReservation.email"
        },
        {
          label: "Req. Loan Amount",
          field: "requestLoanAmount",
          formatFn: (amount) => `RM ${parseInt(amount)}`
        },
        {
          label: "Agent",
          field: "assignedAgent"
        }
      ],
      tableParams: {
        page: 1,
        perPage: 30
      },
      isFilterActive: false,
      filter: {
        "appUser:name[partial]": ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function () {},
  beforeDestroy: function () {},
  mounted: function () {
    this.init();
  },
  methods: {
    async init() {
      await this.getAllBankLoans();
    },
    // ===================== Table related methods =====================
    updateParams(newProps) {
      this.tableParams = Object.assign({}, this.tableParams, newProps);
    },

    onPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ page: params.currentPage });
      this.getAllBankLoans().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },

    onPerPageChange(params) {
      this.$store.commit("setIsLoading", true);

      this.updateParams({ perPage: params.currentPerPage });
      this.getAllBankLoans().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    filterToggle() {
      this.isFilterActive = !this.isFilterActive;
    },
    toLeadDetail(event) {
      let id = event.row.id;
      this.$router.push({
        name: this.detailRouteName,
        params: {
          id: id
        }
      });
    },
    // ===================== Filter related methods =====================
    filterData() {
      this.$store.commit("setIsLoading", true);

      this.tableParams.page = 1;
      // Trim filter
      this.filter = this._.mapValues(this.filter, (item) => item.trim());

      this.getAllBankLoans().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    clearFilter() {
      this.$store.commit("setIsLoading", true);
      this.tableParams.page = 1;
      this.filter = this._.mapValues(this.filter, () => "");
      this.getAllBankLoans().then(() => {
        this.$store.commit("setIsLoading", false);
      });
    },
    // ========================== API Related =========================
    async getAllBankLoans() {
      try {
        this.$store.commit("setIsLoading", true);
        let filteredParam = await this.$nextTick(() => {
          return this.$cleanQueryParam(this.filter);
        });

        let data = await this.getBankLoans({
          queries: {
            ...filteredParam,
            "status[in]": this.status
          },
          page: this.tableParams.page,
          limit: this.tableParams.perPage
        });

        this.bankLoanTableData = this._.cloneDeep(data.data);
        this.bankLoanTablePagination = this._.cloneDeep(data.meta.pagination);
        this.$store.commit("setIsLoading", false);
      } catch (error) {
        this.$store.commit("setIsLoading", false);
        this.$notify({
          group: "alert",
          type: "error",
          title: "Error",
          text: "Failed to fetch data. Please try again later."
        });
      }
    }
  }
};
</script>

<style lang="scss"></style>
